import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import EmabesaProjects from "../../components/sections/projects/emabesa"

const Saneamiento = () => (
  <Layout>
    <Seo title="Benalcons" />
    <EmabesaProjects />
  </Layout>
)

export default Saneamiento
