import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { useTranslation } from "react-i18next"

const EmabesaProjects = props => {
  const { t } = useTranslation("projects")

  return (
    <div>
      <h2 className="uppercase font-inter font-bold font-xl pt-8 pb-5 lg:pb-6 text-center lg:text-4xl">
        {t("indexEmabesa")}
      </h2>
      <div className="grid lg:grid-cols-2 gap-8 place-items-center max-w-6xl mx-auto pb-8 px-2">
        <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
          <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
            <StaticImage
              height={430}
              width={640}
              src="../../../images/acometidas2.png"
              alt="Impermeabilización de techos y tejados."
              placeholder="tracedSVG"
              className="rounded-t-md shadow-md "
            />
            <div className="flex items-center justify-start py-2 w-full">
              <StaticImage
                height={57}
                width={60}
                src="../../../images/emabesa.png"
                alt="Impermeabilización de techos y tejados."
                placeholder="tracedSVG"
                className="rounded-md ml-2 flex-shrink-0"
              />
              <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
                {t("project1")}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
          <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
            <StaticImage
              height={430}
              width={640}
              src="../../../images/acometidas.png"
              alt="Impermeabilización de techos y tejados."
              placeholder="tracedSVG"
              className="rounded-t-md shadow-md "
            />
            <div className="flex items-center justify-start py-2 w-full">
              <StaticImage
                height={57}
                width={60}
                src="../../../images/emabesa.png"
                alt="Impermeabilización de techos y tejados."
                placeholder="tracedSVG"
                className="rounded-md ml-2 flex-shrink-0"
              />
              <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
                {t("project2")}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
          <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
            <StaticImage
              height={430}
              width={640}
              src="../../../images/saneamiento.png"
              alt="Impermeabilización de techos y tejados."
              placeholder="tracedSVG"
              className="rounded-t-md shadow-md "
            />
            <div className="flex items-center justify-start py-2 w-full">
              <StaticImage
                height={57}
                width={60}
                src="../../../images/emabesa.png"
                alt="Impermeabilización de techos y tejados."
                placeholder="tracedSVG"
                className="rounded-md ml-2 flex-shrink-0"
              />
              <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
                {t("project3")}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center max-w-lg shadow-lg rounded-md m-2">
          <div className="flex flex-col lg:flex-row items-center justify-start flex-wrap">
            <StaticImage
              height={430}
              width={640}
              src="../../../images/fontaneriaok.png"
              alt="Impermeabilización de techos y tejados."
              placeholder="tracedSVG"
              className="rounded-t-md shadow-md "
            />
            <div className="flex items-center justify-start py-2 w-full">
              <StaticImage
                height={57}
                width={60}
                src="../../../images/emabesa.png"
                alt="Impermeabilización de techos y tejados."
                placeholder="tracedSVG"
                className="rounded-md ml-2 flex-shrink-0"
              />
              <h3 className="text-center text-base font-montserrat-semi uppercase mx-auto">
                {t("project4")}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmabesaProjects
